/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, useEffect, useState, useRef } from 'react';
import { useRouter, Loading, ErrorPopup, MetaTag } from '../../../components';
import env from '../../../env';
import Slider from "react-slick";
import style from './atdwPageStyle';
//import { MdDirections, MdCall, MdWeb, MdBook, MdArrowDropDownCircle } from "react-icons/md";
import { MdArrowDropDownCircle } from "react-icons/md";
import { FaCircle } from "react-icons/fa";
import { ViewPartial } from '../../../cms/pages/partials/viewPartial';
import { AtdwPagePartial } from '../../../cms/pages/partials/atdwPagePartial';
import { AtdwGallery } from '../atdwGallery/atdwGallery';
import { AtdwSearchMain } from '../atdwSearchMain/atdwSearchMain';
import utils from '../../../utils';
import { gtm } from '../../../lib/tracking';

export function AtdwPage() {

    const slug = useRouter().query.slug;
    const [atdwData, setAtdwData] = useState();
    const [loading, setLoading] = useState();
    const [errors, setErrors] = useState();
    const [openMap, setOpenMap] = useState(false);

    useEffect(() => {
        setAtdwData(null);
        setLoading(true);
        setErrors(null);
    }, [slug]);

    useEffect(() => {

        if (loading !== true) {
            return;
        }

        const abortController = new AbortController();
        const signal = abortController.signal;

        let _class = document.querySelector('div.body-content').className.replace(/theme-color-.*/g, '');
        document.querySelector('div.body-content').className = _class + "theme-color-pink";
        document.querySelector('header#top').classList.remove("itinerary-page");
        document.querySelector('header#top').classList.add("atdw-page");

        fetch(env.apiBase + "/api/atdw/getproduct/" + slug, { signal: signal })
            .then(res => res.json())
            .then(res => {
                const data = res;

                console.log("data--", data);

                if (data.errors && data.errors.length > 0) {
                    setErrors(data.errors[0].errorMessage);
                    return;
                }

                const isAtdwProduct = data.results.isAtdwProduct;
                const atdwDataInDb = data.results.atdwDataInDb;

                if (isAtdwProduct === true) {
                    if (data && data.results && data.results.product) {
                        const _product = data.results.product;
                        const _multimedia = [];
                        const _video = [];
                        const _features = [];
                        const _geoCode = { lat: 0, lng: 0 };
                        let _primaryPhone = "";
                        let _siteUrl = "";
                        let _bookingUrl = "";

                        let va = [];
                        let _tempDataId;
                        for (var n = 0; n < _product.multimedia.length; n++) {
                            if (_product.multimedia[n].attributeIdMultimediaContent === 'IMAGE' &&
                                _product.multimedia[n].attributeIdSizeOrientation === '16X9' &&
                                parseInt(_product.multimedia[n].width) >= 800) {
                                if (!_tempDataId) {
                                    _tempDataId = _product.multimedia[n].multimediaId;
                                }

                                if (_tempDataId === _product.multimedia[n].multimediaId) {
                                    va.push(_product.multimedia[n]);
                                }
                                else {
                                    _multimedia.push(va);
                                    va = [];
                                    va.push(_product.multimedia[n]);
                                    _tempDataId = _product.multimedia[n].multimediaId;
                                }
                            }
                            else if (_product.multimedia[n].attributeIdMultimediaContent === 'VIDEO') {
                                _video.push(_product.multimedia[n]);
                            }

                            if (n === (_product.multimedia.length - 1) && va.length > 0) {
                                _multimedia.push(va);
                            }
                        }

                        for (var i in _product.addresses) {
                            if (_product.addresses[i].attributeIdAddress === "PHYSICAL") {
                                _geoCode.lat = Number(_product.addresses[i].geocodeGdaLatitude);
                                _geoCode.lng = Number(_product.addresses[i].geocodeGdaLongitude);
                                //_address = _product.addresses[i].addressLine1;
                                //_postCode = _product.addresses[i].addressPostalCode;
                                break;
                            }
                        }

                        _product.communication.forEach(el => {
                            if (el.attributeIdCommunicationDescription === 'Primary Phone') {
                                _primaryPhone = el.communicationDetail;
                            }
                            else if (el.attributeIdCommunicationDescription === 'URL Enquiries') {
                                _siteUrl = el.communicationDetail;
                            }
                            else if (el.attributeIdCommunicationDescription === 'Booking URL') {
                                _bookingUrl = el.communicationDetail;
                            }
                        });

                        if (_product.externalSystems.length > 0) {
                            for (var nn in _product.externalSystems) {
                                if (_product.externalSystems[nn].externalSystemCode === "TXA_DEFAULT") {
                                    const externalSystemText = _product.externalSystems[nn].externalSystemText;
                                    _bookingUrl = `https://book.txa.com.au/v4/Pages/Availability.aspx?exl_dn=Destination_Riverland_web&exl_psn=${externalSystemText}&exl_ad=2&exl_ch=0&exl_dte=`;
                                    break;
                                }
                            }
                        }

                        _product.attributes.forEach(el => {
                            if (el.attributeTypeId === "ENTITY FAC") {
                                _features.push(el);
                            }
                            // else if(el.attributeTypeId === "MEMBERSHIP"){
                            //     _flag.push(el);
                            // }
                        });

                        setAtdwData({
                            multimedia: _multimedia,
                            product: _product,
                            features: _features,
                            geoCode: _geoCode,
                            searchTextTitle: "SEARCH " + _product.productCategoryDescription,
                            primaryPhone: _primaryPhone,
                            siteUrl: _siteUrl,
                            bookingUrl: _bookingUrl,
                            isAtdwProduct: isAtdwProduct,
                            atdwType: _product.productCategoryId,
                            atdwDataInDb : atdwDataInDb
                        });
                    }
                    else { }
                }
                else {
                    if (data && data.results && data.results.product) {
                        const _product = data.results.product;
                        const _primaryPhone = _product.communication.find(x => x.attributeIdCommunicationDescription === 'Primary Phone');
                        const _siteUrl = _product.communication.find(x => x.attributeIdCommunicationDescription === 'URL Enquiries');
                        const _bookingUrl = _product.communication.find(x => x.attributeIdCommunicationDescription === 'Booking URL');
                        setAtdwData({
                            multimedia: _product.atdwImages,
                            isAtdwProduct: isAtdwProduct,
                            product: _product,
                            features: _product.features,
                            geoCode: { lat: Number(_product.extraGeoCodeLat), lng: Number(_product.extraGeoCodeLng) },
                            atdwType: _product.productType,
                            primaryPhone: _primaryPhone && _primaryPhone.communicationDetail,
                            siteUrl: _siteUrl && _siteUrl.communicationDetail,
                            bookingUrl: _bookingUrl && _bookingUrl.communicationDetail,
                            atdwDataInDb : atdwDataInDb
                        });
                    }
                    else { }
                }
                setLoading(false);
            })
            .catch(error => {
                console.log("error :: ", error);
                setLoading(false);
            });

        return function cleanup() {
            abortController.abort();
        }

        // eslint-disable-next-line
    }, [loading]);

    // const AtdwMultimediaMemo = useMemo(() => {
    //     return(
    //         atdwData &&
    //         <AtdwMultimedia  multimedia = {atdwData.multimedia} />
    //     )

    //     // eslint-disable-next-line
    // }, [atdwData]);

    if (loading || !atdwData) {
        if (errors) {
            return <ErrorPopup title="Loading Error" errors={errors} />
        }
        return <Loading></Loading>
    }
    return (
        <Fragment>
            <MetaTag data={getMetaTag(atdwData.atdwDataInDb)} />
            <AtdwPageBaner
                multimedia={atdwData.multimedia}
                primaryPhone={atdwData.primaryPhone}
                siteUrl={atdwData.siteUrl}
                bookingUrl={atdwData.bookingUrl}
                isAtdwProduct={atdwData.isAtdwProduct}
                setOpenMap={setOpenMap} />
            <div className="container">
                <div className="htmlPanel">
                    <AtdwPageDetail product={atdwData.product} isAtdwProduct={atdwData.isAtdwProduct} />
                    <AtdwContactDetails communication={atdwData.product.communication} isAtdwProduct={atdwData.isAtdwProduct} />
                    <AtdwFeatures features={atdwData.features} />
                    {
                        (atdwData.product.eventFrequency && atdwData.product.eventFrequency.length > 0) &&
                        <AtdwDateTime dates={atdwData.product.eventFrequency} isAtdwProduct={atdwData.isAtdwProduct} />
                    }
                    <div id="atdwPageLocation">
                        <AtdwLocation geoCode={atdwData.geoCode} productName={atdwData.product.productName} openMap={openMap} setOpenMap={setOpenMap} />
                    </div>
                    <AtdwMultimedia multimedia={atdwData.multimedia} isAtdwProduct={atdwData.isAtdwProduct} />
                    <div css={style.searchFunctionText}>
                        IF THIS LISTING IS NOT WHAT YOU ARE LOOKING FOR, USE THE SEARCH FUNCTION BELOW.
                    </div>
                    <AtdwSearchMain atdwType={atdwData.atdwType} />
                    {
                        atdwData.isAtdwProduct === true &&
                        <img src={atdwData.product.productPixelURL} width='1' height='1' border='0' alt='atdw_pixel_code' />
                    }
                </div>
            </div>
            <AtdwPagePartial></AtdwPagePartial>
            <ViewPartial></ViewPartial>
        </Fragment>
    )
}

function AtdwPageDetail({ product, isAtdwProduct }) {
    function GetDes() {
        if (isAtdwProduct) {
            let _texts = product.productDescription.split("\n");
            return (
                _texts.map((text, index) => <p css={style.productDes} key={index} className={`${'des__' + index}`}>{text}</p>)
            )
        }
        return (
            <div dangerouslySetInnerHTML={{ __html: product.extraDescription }}></div>
        )
    }

    return (
        <section>
            <h1 css={style.productName} className="atdw__page__product__name">
                {product.productName.toUpperCase()}
            </h1>
            <GetDes />
            {
                (product.rateFrom && product.rateFrom !== '') && (product.rateTo && product.rateTo !== '') &&
                <div css={style.productRate}>
                    Rate : {'$' + product.rateFrom} ~ {'$' + product.rateTo}
                </div>
            }
        </section>
    )
}

function AtdwContactDetails({ communication, isAtdwProduct }) {
    const [open, setOpen] = useState(false);
    return (
        <section>
            <div css={style.expendablePanel} className={open ? 'open' : 'close'}>
                <div css={style.expendablePanelTitle} className="atdw__page__expanel__title exPanelTitle" onClick={(e) => {
                    setOpen(!open);
                }}>
                    <span css={style.expanleTitle}> CONTACT DETAILS </span>
                    <MdArrowDropDownCircle />
                </div>
                <div css={style.expendablePanelContent} className="expendablePanelContent">
                    <div style={{ marginTop: '0.5rem' }}></div>
                    {
                        communication.map((item, index) => {
                            if ((isAtdwProduct === true && item.attributeIdCommunicationDescription.toLowerCase().includes("email")) ||
                                (isAtdwProduct === false && item.type === 'email')) {
                                return (
                                    <div key={index}>
                                        <span> {item.attributeIdCommunicationDescription + ' : '} </span>
                                        <a href={'mailto:' + item.communicationDetail} target="_blank" rel="noopener noreferrer"> {item.communicationDetail}</a>
                                    </div>
                                )
                            }
                            else if ((isAtdwProduct === true && item.attributeIdCommunicationDescription.toLowerCase().includes("phone")) ||
                                (isAtdwProduct === false && item.type === 'tel')) {
                                let phoneNumber = item.communicationDetail;
                                if (item.communicationDetail && item.communicationDetail !== '') {
                                    let _phoneNumber = (item.communicationAreaCode ? item.communicationAreaCode : '') + item.communicationDetail.replace(/\s/g, "");
                                    phoneNumber = "+" + item.communicationIsdCode + _phoneNumber.slice(1);
                                }
                                return (
                                    <div key={index}>
                                        <span> {item.attributeIdCommunicationDescription + ' : '} </span>
                                        <a href={'tel:' + phoneNumber} target="_blank" rel="noopener noreferrer"> {item.communicationDetail}</a>
                                    </div>
                                )
                            }
                            else if ((isAtdwProduct === true && item.attributeIdCommunicationDescription.toLowerCase().includes("url")) ||
                                (isAtdwProduct === false && item.type === 'url')) {
                                return (
                                    <div key={index}>
                                        <span> {item.attributeIdCommunicationDescription + ' : '} </span>
                                        <a href={item.communicationDetail.includes("http") ? item.communicationDetail : "https://" + item.communicationDetail} target="_blank" rel="noopener noreferrer"> {item.communicationDetail}</a>
                                    </div>
                                )
                            }
                            else {
                                return (
                                    <div key={index}>
                                        <span> {item.attributeIdCommunicationDescription + ' : '} </span>
                                        <span> {item.communicationDetail} </span>
                                    </div>
                                )
                            }
                        })
                    }
                </div>
            </div>
        </section>
    )
}

function AtdwDateTime({ dates, isAtdwProduct }) {
    
    console.log("dates ---- ", dates);
    
    const [open, setOpen] = useState(false);
    const todayDate = new Date();
    return (
        <section>
            <div css={style.expendablePanel} className={open ? 'open' : 'close'}>
                <div css={style.expendablePanelTitle} className="atdw__page__expanel__title exPanelTitle" onClick={(e) => {
                    setOpen(!open);
                }}>
                    <span css={style.expanleTitle}> DATES &#38; TIME </span>
                    <MdArrowDropDownCircle />
                </div>
                <div css={style.expendablePanelContent} className="expendablePanelContent">
                    <div style={{ marginTop: '0.5rem' }}></div>
                    <div className="row">
                        {
                            dates.map((item, index) => {
                                let pDate = new Date(item.frequencyStartDate);
                                let monthResult = '';
                                let startTime = isAtdwProduct ? item.frequencyStartTime : '';

                                console.log("pDate.getMonth", pDate.getMonth());

                                switch (pDate.getMonth()) {
                                    case 0: monthResult = "January"
                                        break;
                                    case 1: monthResult = "February"
                                        break;
                                    case 2: monthResult = "March"
                                        break;
                                    case 3: monthResult = "April"
                                        break;
                                    case 4: monthResult = "May"
                                        break;
                                    case 5: monthResult = "June"
                                        break;
                                    case 6: monthResult = "July"
                                        break;
                                    case 7: monthResult = "August"
                                        break;
                                    case 8: monthResult = "September"
                                        break;
                                    case 9: monthResult = "October"
                                        break;
                                    case 10: monthResult = "November"
                                        break;
                                    case 11: monthResult = "December"
                                        break;
                                    default: monthResult = ""
                                        break;
                                }

                                if(startTime && startTime !== ''){
                                   const timeList = item.frequencyStartTime.split(":");
                                   if(parseInt(timeList[0]) > 12){
                                        startTime = (timeList[0] - 12) + ":" + timeList[1] + " pm";
                                   }
                                   else{
                                        startTime = timeList[0] + ":" + timeList[1] + ' am';
                                   }
                                }

                                let dateResult = pDate.getDate() + 'th ' + monthResult + " " + pDate.getFullYear() + ((startTime && startTime !== '') ? ' ' + startTime : "");

                                if (pDate >= todayDate) {
                                    return (
                                        <div className="col-6 col-sm-4" key={index} style={{ fontFamily: 'sans-serif' }}>
                                            {
                                                // (('' + pDate.getDate()).length < 2 ? '0' + pDate.getDate() : '' + pDate.getDate()) + '-' +
                                                // (('' + (pDate.getMonth() + 1)).length < 2 ? '0' + (pDate.getMonth() + 1) : '' + (pDate.getMonth() + 1)) + '-' +
                                                // (isAtdwProduct ? pDate.getFullYear() + ' : ' + item.frequencyStartTime : pDate.getFullYear())
                                                dateResult
                                            }
                                        </div>
                                    )
                                }
                                return null;
                            })
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}

function AtdwMultimedia({ multimedia, isAtdwProduct }) {
    const [open, setOpen] = useState(false);
    return (
        <section>
            <div css={style.expendablePanel} className={open ? 'open' : 'close'}>
                <div css={style.expendablePanelTitle} className="atdw__page__expanel__title exPanelTitle" onClick={(e) => {
                    setOpen(!open);
                }}>
                    <span css={style.expanleTitle}> PHOTO GALLERY &#38; VIDEO </span>
                    <MdArrowDropDownCircle />
                </div>
                <div css={style.expendablePanelContent} className="expendablePanelContent">
                    <div style={{ marginTop: '0.5rem' }}></div>
                    <AtdwGallery images={multimedia} isAtdwProduct={isAtdwProduct} />
                </div>
            </div>
        </section>
    )
}

function AtdwFeatures({ features }) {
    const [open, setOpen] = useState(false);
    return (
        <section>
            <div css={style.expendablePanel} className={open ? 'open' : 'close'}>
                <div css={style.expendablePanelTitle} className="atdw__page__expanel__title exPanelTitle" onClick={(e) => {
                    setOpen(!open);
                }}>
                    <span css={style.expanleTitle}> FEATURES </span>
                    <MdArrowDropDownCircle />
                </div>
                <div css={style.expendablePanelContent} className="expendablePanelContent">
                    <div style={{ marginTop: '0.5rem' }}></div>
                    <div className="row">
                        {
                            features.map((item, index) => {
                                return (
                                    <div className="col-12 col-sm-4" key={index}>
                                        <FaCircle style={{ height: '0.2em', width: '0.2em', verticalAlign: 'middle' }} />
                                        <span css={style.content}> {item.attributeIdDescription} </span>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}

function AtdwLocation({ geoCode, productName, openMap, setOpenMap }) {
    //const [open, setOpen] = useState(false);
    const ele = useRef(null);

    useEffect(() => {
        let checkScript = true;
        const script = document.createElement('script');
        if (window['google'] === undefined) {
            checkScript = false;
        }
        else {
            if (window['google'].maps === undefined) {
                checkScript = false;
            }
        }

        // function setupMap(){
        //     //var map, marker;
        //     var map = new window.google.maps.Map(ele.current, {
        //         enter: {lat: geoCode.lat, lng: geoCode.lng},
        //         zoom: 15
        //             //mapId: "77731b8119077e37"
        //     });
        //     var marker = new window.google.maps.Marker({
        //         position: {lat: geoCode.lat, lng: geoCode.lng},
        //         map: map,
        //         title:productName
        //     });
        // }

        if (!checkScript) {
            script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDdeE10fagsW-TTheuB3W-kGvkVTFe3NyY';
            script.async = true;
            document.body.appendChild(script);
            setTimeout(() => {
                //setupMap();

                var map, marker;
                map = new window.google.maps.Map(ele.current, {
                    center: { lat: geoCode.lat, lng: geoCode.lng },
                    zoom: 15
                    //mapId: "77731b8119077e37"
                });

                marker = new window.google.maps.Marker({
                    position: { lat: geoCode.lat, lng: geoCode.lng },
                    map: map,
                    title: productName
                });

                console.log(marker);
            }, 1000);
        }
        else {
            var map, marker;
            map = new window.google.maps.Map(ele.current, {
                center: { lat: geoCode.lat, lng: geoCode.lng },
                zoom: 15
                //mapId: "77731b8119077e37"
            });

            marker = new window.google.maps.Marker({
                position: { lat: geoCode.lat, lng: geoCode.lng },
                map: map,
                title: productName
            });

            console.log(marker);
        }
        return () => {
            if (!checkScript) {
                document.body.removeChild(script);
            }
        }

        // eslint-disable-next-line        
    }, []);

    return (
        <section>
            <div css={style.expendablePanel} className={openMap ? 'open' : 'close'}>
                <div css={style.expendablePanelTitle} className="atdw__page__expanel__title exPanelTitle" onClick={(e) => {

                    // if(!openMap){
                    //     var map, marker;
                    //     map = new window.google.maps.Map(ele.current, {
                    //         center: {lat: geoCode.lat, lng: geoCode.lng},
                    //         zoom: 15
                    //         //mapId: "77731b8119077e37"
                    //     });
                    //     marker = new window.google.maps.Marker({
                    //         position: {lat: geoCode.lat, lng: geoCode.lng},
                    //         map: map,
                    //         title:productName
                    //     });
                    //     setTimeout(() => {
                    //         document.getElementById('atdwPageLocation').scrollIntoView({behavior:'smooth', block:'center'});    
                    //     }, 800);
                    //     console.log(marker);
                    // }

                    setTimeout(() => {
                        document.getElementById('atdwPageLocation').scrollIntoView({ behavior: 'smooth', block: 'center' });
                    }, 800);
                    setOpenMap(!openMap);
                }}>
                    <span css={style.expanleTitle}> LOCATION </span>
                    <MdArrowDropDownCircle />
                </div>
                <div css={style.expendablePanelContent} className="expendablePanelContent">
                    <div style={{ marginTop: '0.5rem' }}></div>
                    <div style={{ height: "450px" }}>
                        <div ref={ele} style={{ height: "100%" }}></div>
                    </div>
                </div>
            </div>
        </section>
    )
}

function AtdwPageBaner({ multimedia, primaryPhone, siteUrl, bookingUrl, setOpenMap, isAtdwProduct }) {

    const settings = {
        dots: false,
        infinite: true,
        speed: 1500,
        autoplaySpeed: 3000,
        fade: true,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const _multimedia = multimedia.length > 0 ? multimedia : [{ imageUrl: '/sites/topoftheyorke/media/banners/natural-indulgence/255254.jpg' }];

    return (
        <section style={{ overflow: 'hidden', position:'relative' }}>
            <div className="container" css={style.bannerConatiner}>
                <Slider {...settings}>
                    {
                        _multimedia.map((item, index) =>
                            <div key={index} css={style.bannerItem} >
                                {
                                    isAtdwProduct === true ? <picture>
                                        {
                                            item.map((_item, _index) => {
                                                return (
                                                    <source key={_index} crossOrigin="anonymous" srcSet={_item.serverPath} media={_item.width <= 800 ? '(min-width:600px)' : null} />
                                                )
                                            })
                                        }
                                        {/* <img src="/assets/logos/logo.png" alt="image2" /> */}
                                        <img src="/sites/topoftheyorke/media/images/banners/aaron-fidge.jpg" alt="image2" />
                                    </picture> : <div style={{ backgroundImage: utils.css.bgUrlStyle(item.imageUrl) }} css={style.backgroundBannerItem}></div>
                                }
                            </div>
                        )
                    }
                </Slider>
                <div css={style.barBtnContainer}>

                    {
                        (primaryPhone && primaryPhone !== '') &&
                        <a href={'tel:' + primaryPhone}>
                            <button css={style.barBtn} className="btn btn-primary" type="button">
                                CONTACT
                            </button>
                        </a>
                    }

                    <button css={style.barBtn} className="btn btn-primary" type="button" onClick={() => {
                        utils.ui.scrollTo('#atdwPageLocation');
                        setOpenMap(true);
                    }}>
                        LOCATION
                    </button>

                    {
                        (siteUrl && siteUrl !== '') &&
                        <a href={siteUrl.includes("http") ? siteUrl : 'https://' + siteUrl} target="_blank" rel="noopener noreferrer">
                            <button css={style.barBtn} className="btn btn-primary" type="button"> WEBSITE </button>
                        </a>
                    }

                    {
                        (bookingUrl && bookingUrl !== '') &&
                        <a href={bookingUrl.includes("http") ? bookingUrl : 'https://' + bookingUrl} target="_blank" rel="noopener noreferrer">
                            <button css={style.barBtn} className="btn btn-primary" type="button"> BOOKINGS </button>
                        </a>
                    }

                </div>
            </div>
            <div css={style.hoverPanel}>
                <div css={style.hoverPanelTop}
                    className={`atdw__page__hover__panel__top ${(siteUrl && siteUrl !== '') || (bookingUrl && bookingUrl !== '') ? 'two-line' : 'one-line'}`}>
                    {/* <div className={`wave-animation-bottom`} style={{ color: "#f26c33" }}>
                        <svg width="1440px" height="25px" viewBox="0 0 1440 25" preserveAspectRatio="none"><g data-svg-origin="0 0" ><path fill="currentColor" d="M 0 12.5 q 360 -25 720 0 t 720 0 t 720 0 t 720 0 t 720 0 t 720 0 V 0 H 0 V 12.5"></path></g></svg>
                    </div> */}
                </div>
            </div>
        </section>
    )
}

function getMetaTag(product) {
    gtm.pageView('/atdwproduct/' + product.slug, product.productName);
    const siteName = env.siteName;
    const titleSuffix = env.titleSuffix;
    return {
        title: product.productName + ' | ' + titleSuffix,
        description: product.shortDesc,
        keywords: '',
        heroImageUrl: product.imageUrl,
        language: 'English',
        country: 'Australia',
        siteName: siteName,
        url: utils.site.resourcePath('/atdwproduct/' + product.slug)
        //canonical: utils.site.resourcePath(pageData.pageUrl)
    }
}