const overloading = {
  breakpointNames: ['sm', 'md', 'lg', 'xl', 'xxl'],
  breakpoints: [576, 768, 992, 1200, 1600]
}

const colors = {
  orangeColor : '#f4833a',
  pinkColor : '#ee4165',
  darkBlueColor : '#2174a1',
  aquaColor : '#49ad9e',
  greyColor : '#505050',
  lightGrayColor : '#f9f6f6'
}

const variables = {
  familyBase: '"sofia-pro",sans-serif',
  familyHeader: '"sofia-pro",serif',

  primaryColor: '#c20f2f',
  fontColor: colors.greyColor,
  bgColor: '#ebebeb',

  ...colors,
  ...overloading
}

export default variables;