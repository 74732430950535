/** @jsx jsx */
import { jsx } from '@emotion/core';
import utils from 'utils';
import cmsUtils from '../../cmsUtils';
import { AtdwSearchMain } from '../../../atdw/atdwSearchMain/atdwSearchMain';

export function AtdwSearch(props){

    const item = props.item;
    const cmsOption = props.cmsOption || {};
    const cssClass = utils.classNames('cms_item', 'atdw-search', item.cssClass || item.anchorName || '');
    const bgColor = cmsUtils.payload(item, 'BgColor');
    const imageUrl = cmsUtils.payload(item, 'ImageUrl');
    const backgroundTile = cmsUtils.payload(item, 'BackgroundTile');
    
    const atdwType = cmsUtils.payload(item, 'ProductType') || 'ACCOMM';
    const subTypes = cmsUtils.payload(item, 'SubTypes') || '';
    const autoSearch = cmsUtils.payload(item, 'AutoSearch') || 'off';
    const hideSearch = cmsUtils.payload(item, 'HideSearch') || 'off';
    const numOfTileLines = cmsUtils.payload(item, 'NumOfTileLines') || '12';
    const town = cmsUtils.payload(item, 'Town') || 'ALL';
    
    let bgUrl = null;
    if (imageUrl){
        bgUrl = utils.site.resourcePath(imageUrl);
    }
    else if (backgroundTile){
        bgUrl = utils.site.resourcePath(`/dist/transparent-textures/textures/${backgroundTile}`);
    }

    return(
        <section style={{backgroundColor: `${bgColor}`, backgroundImage: bgUrl && utils.css.bgUrlStyle(bgUrl), padding: '1rem 0px'}} className = {cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
            <div className = "container">
                <AtdwSearchMain atdwType = {atdwType} subTypes = {subTypes}  autoSearch = {autoSearch} hideSearch = {hideSearch} numOfTileLines= {numOfTileLines} town = {town} />
            </div>
        </section>            
    )
}