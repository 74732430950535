/** @jsx jsx */
import { jsx } from '@emotion/core';
import style from './atdwSearchResultStyle';
import { AtdwTile } from '../atdwTile/atdwTile';

export function AtdwSearchResult({atdwData}){
    const _atdwData = atdwData || [];
    return(
        <div className="atdwResult">
            <div css={style.atdwTiles} className="atdwTiles">
                {
                    _atdwData.map((item, index) => <AtdwTile {...item} key={ index } />)
                }
            </div>
        </div>
    )
}