/** @jsx jsx */
import { jsx } from '@emotion/core';
import utils from 'utils';
import cmsUtils from '../../cmsUtils';
import { AtdwSearchMain } from '../../../atdw/atdwSearchMain/atdwSearchMain';

export function AtdwFeaturedSearch(props){

    const item = props.item;
    
    const cmsOption = props.cmsOption || {};
    const cssClass = utils.classNames('cms_item', 'atdw-search', item.cssClass || item.anchorName || '');
    const bgColor = cmsUtils.payload(item, 'BgColor');
    const imageUrl = cmsUtils.payload(item, 'ImageUrl');
    const backgroundTile = cmsUtils.payload(item, 'BackgroundTile');
    
    const atdwType = cmsUtils.payload(item, 'FeaturedCategory') || 'FEATUREDHOMEPAGE';
    const numOfTileLines = cmsUtils.payload(item, 'NumOfTileLines') || '12';
    const autoSearch = "on";
    const hideSearch = "on";
    const hideCount = "on";
    
    let bgUrl = null;
    if (imageUrl){
        bgUrl = utils.site.resourcePath(imageUrl);
    }
    else if (backgroundTile){
        bgUrl = utils.site.resourcePath(`/dist/transparent-textures/textures/${backgroundTile}`);
    }

    return(
        <section style={{backgroundColor: `${bgColor}`, backgroundImage: bgUrl && utils.css.bgUrlStyle(bgUrl), padding: '1rem 0px'}} className = {cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
            <div className = "container">
                <AtdwSearchMain atdwType = {atdwType} autoSearch = {autoSearch} hideSearch = {hideSearch} hideCount = {hideCount} numOfTileLines= {numOfTileLines} />
            </div>
        </section>
    )
}