import { css } from "@emotion/core";
import { mq, variables, mqMax } from "../../../cssInJs";
import facepaint from "facepaint";

const breakpointsM = [576, 768, 992, 1200, 1300, 1400, 1500, 1600, 1700];

const mqm = facepaint(breakpointsM.map((bp) => `@media (min-width: ${bp}px)`));

export default {
  pageTiles: css(
    mqm({
      //border: '1px solid red',

      display: "flex",
      justifyContent: "flex-start",
      flexWrap: "wrap",
      marginTop: "2rem",
      marginBottom: "2rem",

      marginLeft: "-10px",
      marginRight: "-10px",
    })
  ),

  pageTile__tile: css(
    mq({
      flex: "0 0 100%",
      maxWidth: "100%",
      padding: "10px",
      paddingTop: "1.5rem",
      paddingBottom: "1.5rem",
      zIndex: 1,

      //2
      "&:nth-of-type(even)": {
        ".pageTile__bsyc__bg__before": mqm({
          bottom: "0px",
          right: "0px",
        }),
        ".pageTile__bsyc__content__details__befor": mqm({
          top: "0px",
          left: "0%",
        }),
      },
      //1
      "&:nth-of-type(odd)": {
        ".pageTile__bsyc__bg__before": mqm({
          top: "0px",
          left: "0px",
        }),
        ".pageTile__bsyc__content__details__befor": mqm({
          bottom: "0px",
          right: "0%",
        }),
      },
      "&:nth-of-type(1)": {},
    })
  ),

  tile__content: css({
    "&:hover": {
      textDecoration: "none",
      "& .pageTile__bsyc__content__details": {
        //color: 'white',
        "&.pink": css({
          backgroundColor: variables.pinkColor,
        }),
        "&.darkblue": css({
          backgroundColor: variables.darkBlueColor,
        }),
        ".pageTile__bsyc__title, .pageTile__bsyc__desc": {
          color: "white !important",
        },
        ".pageTile__bsyc__container__wrapper.line::after": {
          borderRightColor: "#f9f6f6",
        },

        ".pageTile__bsyc__line": {
          borderTopColor: "#f9f6f6 !important",
        },
      },
      "& .pageTile__bsyc__bg2": {
        //transform: scale(1.05)
        //transform : 'scale(1.05)'
      },
    },
  }),

  tile__flip__wrapper: css(
    mqm({
      cursor: "pointer",
      position: "relative",
      width: "100%",

      //border:'1px solid red',
      height: [
        "auto",
        null,
        "270px",
        "348px",
        "427px",
        "443px",
        "450px",
        "450px",
        "450px",
        "450px",
      ],
      zIndex: -1,
    })
  ),

  tile__bg__before: css(
    mqm({
      width: ["auto", null, null, "460px", "580px"],
      position: ["static", null, null, "absolute"],
      zIndex: 1,
    })
  ),

  tile__details__before: css(
    mqm({
      width: ["90%", null, null, "460px", "550px"],
      position: ["static", null, null, "absolute"],
      height: ["auto", null, null, "300px", "360px"],
      marginLeft: ["auto", null, null, "unset"],
      marginRight: ["auto", null, null, "unset"],
      marginTop: ["-2rem", null, null, "0px"],
    })
  ),

  tile__bg: css(
    mqm({
      //height: ['230px', null, null, '300px',  '400px'],
      height: ["auto", null, null, "300px", "400px"],
      overflow: "hidden",
      position: "relative",
      boxShadow: "3px 5px 10px rgba(0,0,0,0.3)",
      "&.odd": mq({}),
    })
  ),

  tile__bg2__inner__wrapper: css(
    mqm({
      //height: ['232px', null, null, '300px', '400px'],
      height: ["auto", null, null, "300px", "400px"],
      backgroundColor: "#f9f6f6",
      padding: "0.3rem",
    })
  ),

  tile__bg2: css(
    mq({
      height: "100%",
      //backgroundPosition: '50%',
      //backgroundRepeat: 'no-repeat',
      //backgroundSize: 'cover',
      overflow: "hidden",
      width: "100%",

      // '.slick-slide > div' : mqm({
      //     height: ['calc(100% - 2px)', null, null, '100%']
      // }),

      ".slick-slide": {
        [mqMax.md]: {
          lineHeight: 0,
        },
      },

      ".slick-next, .slick-prev": {
        display: "none !important",
      },
      // '&.even' : mq({
      //     '.slick-next' : mq({
      //         right: '70px',
      //         display:'none',
      //     })
      // }),
      // '&.odd' : mq({
      //     '.slick-prev' : mq({
      //         left: '70px',
      //         display:'none',
      //     })
      // })
    })
  ),

  tile__bg2__inner: css(
    mqm({
      backgroundPosition: "50%",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      width: "100%",
      height: ["unset", null, null, "100%"],
      paddingTop: ["56.25%", null, null, "unset"],
    })
  ),

  tile__details: css(
    mqm({
      overflow: "hidden",
      textAlign: "center",
      width: "100%",
      height: "100%",

      transition: "all .2s ease-in-out",
      position: "relative",

      boxShadow: "3px 5px 10px rgba(0,0,0,0.3)",
      bottom: "0",

      flexDirection: "row",
      flexWrap: "wrap",
      backgroundColor: "#f9f6f6",

      display: ["block", null, null, "flex"],

      "&.odd": mq({
        ".pageTile__bsyc__container__wrapper.line": {
          order: 2,
          //border:'1px solid blue'
        },
        ".pageTile__bsyc__container__wrapper.line::after": {
          left: "10px",
          right: "unset",
        },
      }),

      "& .pageTile__bsyc__container__wrapper": mqm({
        position: "relative",
        // flex: '1 0 50%',
        // maxWidth: ['100%', null, null, '50%']
        flex: "1 0 90%",
        maxWidth: ["100%", null, null, "90%"],
      }),
      "& .pageTile__bsyc__container__wrapper.line": {
        flex: "1 0 10%",
        maxWidth: ["100%", null, null, "10%"],

        "&::after": {
          content: '""',
          position: "absolute",
          right: "10px",
          top: "50%",
          transform: "translateY(-50%)",
          //borderRight: '1px solid',
          height: "90%",
        },
        "&.pink::after": {
          //borderRightColor: variables.pinkColor
        },
        "&.darkblue::after": {
          //borderRightColor: variables.darkBlueColor
        },
      },
      "& .pageTile__bsyc__container": mq({
        position: ["static", null, "absolute"],
        top: "50%",
        left: "50%",
        transform: ["none", null, "translate(-50%, -50%)"],
        width: ["100%", null, "75%"],
        padding: ["1rem", null, "0px"],
        textAlign: "left",
        "& .pageTile__bsyc__title": mqm({
          //fontFamily: 'proxima-soft, sans-serif',
          fontSize: "1.2rem",
          textTransform: "uppercase",
          fontWeight: "700",
          lineHeight: "1",
          margin: "0",
          //paddingTop: '.5rem',
          paddingBottom: ["0.2rem", null, null, "0.5rem"],
          //paddingBottom: ['0.5rem'],
          "&.pink": {
            color: variables.pinkColor,
          },
          "&.darkblue": {
            color: variables.darkBlueColor,
          },
        }),
        "& .pageTile__bsyc__line": mqm({
          width: "70%",
          marginTop: "0.5rem",
          marginBottom: "0.5rem",
          marginLeft: "0px",
          marginRight: "0px",
          //display: ['block', null, null, 'none'],
          display: ["block", null, null, "block"],
          "&.pink": {
            borderTopColor: variables.pinkColor,
          },
          "&.darkblue": {
            borderTopColor: variables.darkBlueColor,
          },
        }),
        "& .pageTile__bsyc__desc": {
          lineHeight: "1.5",
          fontSize: ".8rem",
          minHeight: "70px",
          color: variables.fontColor,
        },
      }),
    })
  ),
};
