/** @jsx jsx */
import { jsx } from '@emotion/core'
import style from './atdwGalleryStyle';
import { useState, Fragment, useEffect } from 'react';
import { Portal } from "site/portal/portal";
import FsLightbox from 'fslightbox-react';
//import { useLayoutEffect } from 'react';

export function AtdwGallery({ images, isAtdwProduct, useSlider = false }){
    
    const [popupImages, setPopupImages] = useState();
    const [galleryImages, setGalleryImages] = useState();
    const [popupIndex, setPopupIndex] = useState(0);
    const [toggler, setToggler] = useState(true);
    
    useEffect(() => {
        if(galleryImages){
            return;
        }
        const _popupImages = [];
        const _galleryImages = [];

        if(isAtdwProduct === true){
            images.forEach(el => {
                let _popupImage = null;
                let _galleryImage = null;
                el.forEach(eel => {
                    if(_popupImage === null){
                        _popupImage = eel;
                    }
                    else{
                        if(parseInt(_popupImage.width) < parseInt(eel.width)){
                            _popupImage = eel;
                        }
                    }
                    if(_galleryImage === null){
                        _galleryImage = eel;
                    }
                    else{
                        if(parseInt(_galleryImage.width) > parseInt(eel.width)){
                            _galleryImage = eel;
                        }
                    }
                });            
                _popupImages.push(_popupImage.serverPath);
                _galleryImages.push(_galleryImage.serverPath);
            });
        }
        else{
            images.forEach(el => {
                _popupImages.push(el.imageUrl);
                _galleryImages.push(el.imageUrl);
            });
        }

        setPopupImages(_popupImages);
        setGalleryImages(_galleryImages);

        // eslint-disable-next-line
    }, []);

    return(
        <Fragment>
            <Portal>
                <FsLightbox toggler={toggler} sources={popupImages} sourceIndex={popupIndex} />
            </Portal>
            <div css={style.container}>
            {
                (galleryImages && galleryImages.length > 0) &&
                galleryImages.map((item, index) =>
                    <div key={index} css={style.imgItemContainer} onClick={(e) => {
                        e.stopPropagation(); e.preventDefault();
                        setPopupIndex(index >= 0 ? index : 0);
                        setToggler(!toggler);
                    }}>
                        <div css={style.imgItem} style={{backgroundImage:`url("${item}")` }}></div>
                    </div>
                )
            }
            </div>
        </Fragment>
    )
}