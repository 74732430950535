export default {
    buttonContainer : {
        textAlign: 'center',
        marginTop: '1rem'
    },
    topBar : {
        marginTop: '1.5rem',
        //marginBottom: '1rem'
    },
    bottomBar : {
        marginTop: '.5rem',
        textAlign: 'right'
    },
    button:{
        fontWeight: 600
    },
    noResultText :{
        textAlign: 'center',
        marginTop: '1rem',
        fontWeight: 600
    }
}