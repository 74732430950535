import React from 'react';
import ValidationAlert from '../components/ValidationAlert';
import utils from '../../../utils';
import { getLabel } from '../formRenderHelper';

function RadioListInput({ control, handleChange, formOption }) {
    const { name, label, value, errors, style, options, helpText, attrs } = control;
    const fieldId = utils.random.shortId();
    const fieldLabel = getLabel(control, formOption);
    return (
        <div className={`ff ff--radiolist form-group ${style && style.className}`}>
            {fieldLabel && <label className="label">{fieldLabel}</label>}
            <div className="ff__input controlOptions">
                {options && options.map((option, index) =>
                    <div className={`form-check ${style && style.inline && 'form-check-inline'}`} key={option.value}>
                        <input className="form-check-input" name={name} type="radio"
                            checked={checked(value, option.value)}
                            onChange={(e) => handleChange(e, control)}
                            value={option.value} id={fieldId + '_' + index} {...attrs} />
                        <label className="form-check-label" htmlFor={fieldId + '_' + index}>
                            {option.name}
                        </label>
                        {
                            (option.helpText && option.helpText !== '') &&
                            <label className={`form-check-label help-text ${(option.id && option.id !== '') && option.id}`}>
                                {option.helpText}
                            </label>
                        }
                    </div>)}
            </div>
            {helpText && <div className="ff__help" dangerouslySetInnerHTML={{ __html: helpText }}></div>}
            <ValidationAlert errors={errors} />
        </div>
    )
}

function checked(value, optionValue) {
    if (optionValue === null || typeof optionValue === 'undefined') return false;
    return value === optionValue;
}

export default RadioListInput;