/** @jsx jsx */
import { jsx } from '@emotion/core';
import style from './atdwSearchOptionStyle';
import { useState } from 'react';

export function AtdwSearchOption({atdwType, searechAtdw, towns, subCategoies, town}){

    const atdwTypeOptions = setAtdwTypeOptions();
    function setAtdwTypeOptions(){
        if(atdwType === 'ACCOMM'){
            return {title : 'Accommodation Type',
                        items : [
                    { value: '', label: 'ALL' },
                    { value: 'APARTMENT', label: 'Apartments' },
                    { value: 'BACKPACKER', label: 'Backpackers and Hostels' },
                    { value: 'BEDBREAKFA', label: 'Bed and Breakfasts' },
                    { value: 'CABCOTTAGE', label: 'Cabins and Cottages' },
                    { value: 'VANCAMP', label: 'Caravan and Camping' },
                    { value: 'ECOACCOMM', label: 'Eco Accommodation' }, 
                    { value: 'FARMSTAY', label: 'Farm Stays' },
                    { value: 'HISTORICAL', label: 'Historical' },
                    { value: 'HOLHOUSE', label: 'Holiday Houses' },
                    { value: 'HOUSEBOATS', label: 'Houseboats' },
                    { value: 'LUXURYACCOMM', label: 'Luxury Accommodation' },
                    { value: 'HOTELMOTEL', label: 'Hotels and Motels' },
                    //{ value: 'MOTEL', label: 'Motels' },
                    { value: 'RESORTRETREAT', label: 'Resorts and Retreats' },
                    //{ value: 'RETREAT', label: 'Retreats' },
                    { value: 'SELFCONTAINED', label: 'Self Contained' },
                    { value: 'WATERFRONT', label: 'Waterfront' }
                    //{ value : 'FEATUREDACCOMM', label : 'Featured Accomodation' }
            ]};
        }
        else if(atdwType === 'ATTRACTION'){
            return {title : 'Attraction Type',
                        items : [
                    { value: '', label: 'ALL' },
                    { value : 'AMUSETHEME', label : 'Amusement and Theme Parks'},
                    { value : 'ENTERTAIN', label : 'Entertainment Venues' },
                    { value : 'GALMUSECOL', label : 'Galleries, Museums and Collections' },
                    { value : 'HISTHERITG', label : 'Historical Sites and Heritage Locations' },
                    { value : 'LMARKBLD', label : 'Landmarks and Buildings' },
                    { value : 'MINDUSTRY', label : 'Agri, Mining and Industry' },
                    { value : 'NATATTRACT', label : 'Natural Attractions' },
                    { value : 'NATPARKRES', label : 'National Parks and Reserves' },
                    { value : 'OBSVPLANET', label : 'Observatories and Planetariums' },
                    { value : 'PKGDNCEM', label : 'Parks and Gardens' },
                    { value : 'SHOPPING', label : 'Shopping and Markets' },
                    { value : 'SPARETREAT', label : 'Spas and Retreats' },
                    { value : 'SPORTREC', label : 'Sports and Recreation Facilities' },
                    { value : 'ZOOSNCAQU', label : 'Zoos, Sanctuaries, Aquariums and Wildlife Parks' }
                    //{ value : 'FEATUREDATTRACTIONS', label : 'Featured Attractions', }
            ]}
        }
        else if(atdwType === 'DESTINFO'){
            return {title : 'Destination Info',
                     items : [
                        //  { value: 'SHOPPING', label: 'Shopping and Markets' },
                        //  { value: 'SPARETREAT', label: 'Spas and Retreats' },
                        ]
                    }
        }
        else if(atdwType === 'RESTAURANT'){
            return {title : 'Food & Drink Type',
                     items : [
                        //  { value: 'BAR', label: 'Bar' },
                        //  { value: 'TAKEAWAY', label: 'Takeaway Available' },
                        ]
                    }
        }
        else if(atdwType === 'EVENT'){
            return {title : 'Event Type',
                        items : [
                    { value: '', label: 'ALL' },
                    { value : 'EVTBUS', label : 'Business Event' },
                    { value : 'EVTCLASS', label : 'Classes, Lessons, Workshops and Talks' },
                    { value : 'EVTCOMNTY', label : 'Community Event' },
                    { value : 'EVTFOOD', label : 'Food and Wine' },
                    { value : 'EVTMARKET', label : 'Markets' },
                    { value : 'EXHIBIT', label : 'Exhibition and Shows' },
                    { value : 'FESTIVAL', label : 'Festivals and Celebrations' },
                    { value : 'PERFORMANC', label : 'Concert or Performance' },
                    { value : 'SPORT', label : 'Sporting Events' },
                    { value : 'FEATUREDEVENTS', label : 'Featured Events', }
            ]}
        }
        else if(atdwType === 'HIRE'){
            return {title : 'Hire Type',
                        items : [
                            { value: '', label: 'ALL' },
                            { value : 'WATERFRONT', label : 'Waterfront', },
                            { value : 'HOUSEBOATS', label : 'Houseboats', },
                            { value : 'ACCOMMODATION', label : 'Accommodation', },
                            { value : 'FEATUREDHIRES', label : 'Featured Hires', }
                        ]
                    }
        }
        else if(atdwType === 'TOUR'){
            return {title : 'Tour Type',
                        items : [
                            { value: '', label: 'ALL' },
                            { value : 'CRUISES', label : 'Cruises', },
                            { value : 'FEATUREDTOURS', label : 'Featured Tours', }
                        ]
                    }
        }
        else if(atdwType === 'GENSERVICE'){
            return {title : 'Service Type',
                     items : [
                        //  { value: 'CARPARK', label: 'Carpark' },
                        //  { value: 'FAMLYFREND', label: 'Family Friendly' },
                        ]
                    }
        }
        else if(atdwType === 'TOUR'){
            return {title : 'Tour Type',
                     items : [
                        //  { value: 'AOTOURS', label: 'Adventure and Outdoors Tours' },
                        //  { value: 'AHBTOURS', label: 'Air, Helicopter and Balloon Tours' },
                        ]
                    }
        }
        else
        {
            return {title : '', items : []}
        }
    }
    return(
        <SearchForm atdwTypeOptions = {atdwTypeOptions} searechAtdw = {searechAtdw} towns = {towns} atdwType = {atdwType} subCategoies = {subCategoies} town = {town} />
    )
}

function SearchForm({atdwTypeOptions, searechAtdw, towns, atdwType, subCategoies, town}){
    
    const [searchOptionValue, setSearchOptionValue] = useState({
        atdwCheckIn : '',
        atdwCheckOut : '',
        atdwAdults : '1',
        atdwKids : '0',
        atdwSubType : (subCategoies && subCategoies.length > 0) ? subCategoies : [''],
        atdwSearchByName : '',
        atdwTown : town === 'ALL' ? '' : town
    });
    
    function handChange(el){
        const elName = el.target.name;
        const elValue =el.target.value;

        if(elName === "atdwSubType"){
            let value = Array.from(el.target.selectedOptions, option => option.value);
            setSearchOptionValue({...searchOptionValue, [elName] : value});
        }
        else{
            setSearchOptionValue({...searchOptionValue, [elName] : elValue});
        }
    }
    
    function onSubmit(e){
        e.preventDefault();
        e.stopPropagation();
        searechAtdw(searchOptionValue);
    }

    return(
        <form css={style.form} onSubmit = {onSubmit}>
            
            {
                atdwType === 'ACCOMM' &&
                <div className="row">
                <div className="col-md-4">
                    
                    <div className="form-group">
                        <label css={style.label} htmlFor="atdwCheckIn" style={{fontWeight: 400}}> Check In </label>
                        <input type="date" id="atdwCheckIn" name="atdwCheckIn" className="form-control form-control-sm" placeholder="" autoComplete="off" value={searchOptionValue.atdwCheckIn} onChange={handChange} />
                    </div>
                    <div className="form-group">
                        <label css={style.label} htmlFor="atdwCheckOut"> Check Out </label>
                        <input type="date" id="atdwCheckOut" name="atdwCheckOut" className="form-control form-control-sm" placeholder="" autoComplete="off" value={searchOptionValue.atdwCheckOut} onChange={handChange}/>
                    </div>

                    <div className="form-row">
                        <div css={style.formLast} className="form-group col-6">
                            <label css={style.label} htmlFor="atdwAdults" style={{fontWeight: 400}}>Adults</label>
                            <select className="form-control form-control-sm" id="atdwAdults" name="atdwAdults" value={searchOptionValue.atdwAdults} onChange={handChange}>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                            </select>
                        </div>
                        <div css={style.formLast} className="form-group col-6">
                            <label css={style.label} htmlFor="atdwKids" style={{fontWeight: 400}}>Kids</label>
                            <select className="form-control form-control-sm" id="atdwKids" name="atdwKids" value={searchOptionValue.atdwKids} onChange={handChange}>
                                <option value="0">0</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                            </select>
                        </div>
                    </div>

                </div>
                <div className="col-md-4">
                    <div css={style.formLast} className="form-group">
                        <label css={style.label} htmlFor="atdwSubType"> {atdwTypeOptions.title} </label>
                        <select css={style.atdwTypeSelect} multiple className="form-control form-control-sm" id="atdwSubType" name="atdwSubType" value={searchOptionValue.atdwSubType} onChange={handChange}>
                            {
                                atdwTypeOptions.items.map((item, index) => 
                                    <option key={index} value={item.value}> {item.label} </option>
                                )
                            }
                        </select>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <label css={style.label} htmlFor="atdwTown"> Town </label>
                        <select className="form-control form-control-sm" id="atdwTown" name="atdwTown" value={searchOptionValue.atdwTown} onChange={handChange}>
                                <option value=""> ALL </option>
                                {
                                    towns.map((item, index) =>
                                    <option key={index} value={item}> {item} </option>
                                    )
                                }
                                
                        </select>
                    </div>
                    <div className="form-group">
                        <label css={style.label} htmlFor="atdwSearchByName"> Search by Name </label>
                        <input type="text" className="form-control form-control-sm" placeholder="" name="atdwSearchByName" id="atdwSearchByName" autoComplete="off" value={searchOptionValue.atdwSearchByName} onChange={handChange} />
                    </div>
                    <div css={style.formLast} className="form-group">
                        <button css={style.searchBtn} style={{width:'100%'}} className="btn btn-primary" type="submit">
                            SEARCH
                        </button>
                    </div>
                </div>
                </div>
            }
            {
                (atdwType === 'DESTINFO' || atdwType === 'RESTAURANT' || atdwType === 'GENSERVICE') &&
                <div className="row">
                <div className="col-md-4">
                    <div className="form-group">
                        <label css={style.label} htmlFor="atdwTown"> Town </label>
                        <select className="form-control form-control-sm" id="atdwTown" name="atdwTown" value={searchOptionValue.atdwTown} onChange={handChange}>
                                <option value=""> ALL </option>
                                {
                                    towns.map((item, index) =>
                                    <option key={index} value={item}> {item} </option>
                                    )
                                }
                                
                        </select>
                    </div>
                </div>
                <div className="col-md-4">
                <div className="form-group">
                        <label css={style.label} htmlFor="atdwSearchByName"> Search by Name </label>
                        <input type="text" className="form-control form-control-sm" placeholder="" name="atdwSearchByName" id="atdwSearchByName" autoComplete="off" value={searchOptionValue.atdwSearchByName} onChange={handChange} />
                </div>
                
                </div>
                <div className="col-md-4">
                <div css={style.formLast} className="form-group">
                        {/* <label css={style.label}> Search by Name </label> */}
                        <button css={style.searchBtn} style={{width:'100%'}} className="btn btn-primary" type="submit">
                            SEARCH
                        </button>
                    </div>
                </div>
                </div>
            }
            {
                (atdwType === 'HIRE' || atdwType === 'TOUR') &&
                <div className="row">
                    <div className="col-md-6">
                    <div css={style.formLast} className="form-group">
                            <label css={style.label} htmlFor="atdwSubType"> {atdwTypeOptions.title} </label>
                            <select css={style.atdwTypeSelect} multiple className="form-control form-control-sm" id="atdwSubType" name="atdwSubType" value={searchOptionValue.atdwSubType} onChange={handChange}>
                            {
                                atdwTypeOptions.items.map((item, index) => 
                                    <option key={index} value={item.value}> {item.label} </option>
                                )
                            }
                            </select>
                        </div>
                    </div>
                    <div className="col-md-6">
                    <div className="form-group">
                        <label css={style.label} htmlFor="atdwTown"> Town </label>
                        <select className="form-control form-control-sm" id="atdwTown" name="atdwTown" value={searchOptionValue.atdwTown} onChange={handChange}>
                                <option value=""> ALL </option>
                                {
                                    towns.map((item, index) =>
                                    <option key={index} value={item}> {item} </option>
                                    )
                                }
                                
                        </select>
                    </div>
                    <div className="form-group">
                        <label css={style.label} htmlFor="atdwSearchByName"> Search by Name </label>
                        <input type="text" className="form-control form-control-sm" placeholder="" name="atdwSearchByName" id="atdwSearchByName" autoComplete="off" value={searchOptionValue.atdwSearchByName} onChange={handChange} />
                    </div>
                    <div css={style.formLast} className="form-group">
                        <button css={style.searchBtn} style={{width:'100%'}} className="btn btn-primary" type="submit">
                            SEARCH
                        </button>
                    </div>

                    </div>
                </div>
            }
            {
                (atdwType === 'EVENT') &&
                <div className="row">
                    <div className="col-md-4">
                        <div className="form-group">
                            <label css={style.label} htmlFor="atdwCheckIn" style={{fontWeight: 400}}> From </label>
                            <input type="date" id="atdwCheckIn" name="atdwCheckIn" className="form-control form-control-sm" placeholder="" autoComplete="off" value={searchOptionValue.atdwCheckIn} onChange={handChange} />
                        </div>
                        <div className="form-group">
                            <label css={style.label} htmlFor="atdwCheckOut"> To </label>
                            <input type="date" id="atdwCheckOut" name="atdwCheckOut" className="form-control form-control-sm" placeholder="" autoComplete="off" value={searchOptionValue.atdwCheckOut} onChange={handChange}/>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div css={style.formLast} className="form-group">
                            <label css={style.label} htmlFor="atdwSubType"> {atdwTypeOptions.title} </label>
                            <select css={style.atdwTypeSelect} multiple className="form-control form-control-sm" id="atdwSubType" name="atdwSubType" value={searchOptionValue.atdwSubType} onChange={handChange}>
                            {
                                atdwTypeOptions.items.map((item, index) => 
                                    <option key={index} value={item.value}> {item.label} </option>
                                )
                            }
                            </select>
                        </div>
                    </div>
                    
                    <div className="col-md-4">
                        <div className="form-group">
                            <label css={style.label} htmlFor="atdwTown"> Town </label>
                            <select className="form-control form-control-sm" id="atdwTown" name="atdwTown" value={searchOptionValue.atdwTown} onChange={handChange}>
                                <option value=""> ALL </option>
                                {
                                    towns.map((item, index) =>
                                    <option key={index} value={item}> {item} </option>
                                    )
                                }
                                
                            </select>
                        </div>

                        <div className="form-group">
                            <label css={style.label} htmlFor="atdwSearchByName"> Search by Name </label>
                            <input type="text" className="form-control form-control-sm" placeholder="" name="atdwSearchByName" id="atdwSearchByName" autoComplete="off" value={searchOptionValue.atdwSearchByName} onChange={handChange} />
                        </div>
                     
                        <div css={style.formLast} className="form-group">
                        {/* <label css={style.label}> Search by Name </label> */}
                            <button css={style.searchBtn} style={{width:'100%'}} className="btn btn-primary" type="submit">
                                SEARCH
                            </button>
                        </div>

                    </div>

                </div>
            }
            {
                atdwType === 'ATTRACTION' &&
                <div className="row">

                    <div className="col-md-6">
                        <div css={style.formLast} className="form-group">
                            <label css={style.label} htmlFor="atdwSubType"> {atdwTypeOptions.title} </label>
                            <select css={style.atdwTypeSelect} multiple className="form-control form-control-sm" id="atdwSubType" name="atdwSubType" value={searchOptionValue.atdwSubType} onChange={handChange}>
                            {
                                atdwTypeOptions.items.map((item, index) => 
                                    <option key={index} value={item.value}> {item.label} </option>
                                )
                            }
                            </select>
                        </div>
                    </div>
                    
                    <div className="col-md-6">
                        <div className="form-group">
                            <label css={style.label} htmlFor="atdwTown"> Town </label>
                            <select className="form-control form-control-sm" id="atdwTown" name="atdwTown" value={searchOptionValue.atdwTown} onChange={handChange}>
                                <option value=""> ALL </option>
                                {
                                    towns.map((item, index) =>
                                    <option key={index} value={item}> {item} </option>
                                    )
                                }
                                
                            </select>
                        </div>

                        <div className="form-group">
                            <label css={style.label} htmlFor="atdwSearchByName"> Search by Name </label>
                            <input type="text" className="form-control form-control-sm" placeholder="" name="atdwSearchByName" id="atdwSearchByName" autoComplete="off" value={searchOptionValue.atdwSearchByName} onChange={handChange} />
                        </div>
                     
                        <div css={style.formLast} className="form-group">
                        {/* <label css={style.label}> Search by Name </label> */}
                            <button css={style.searchBtn} style={{width:'100%'}} className="btn btn-primary" type="submit">
                                SEARCH
                            </button>
                        </div>
                    </div>
                    
                </div>

            }
        </form>
    )
}