/** @jsx jsx */
import { jsx } from "@emotion/core";

import { useState, Fragment } from "react";
import { SiteLink } from "../../components";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { NavBar } from "./navBar";
import { BackButton } from "../backButton/backButton";
import { Hamburger } from "../hamburger/hamburger";
import style from "./topHeaderStyle";
import { useStore } from "../../store";
import cmsUtils from "../../cms/utils/cmsUtils"; //'../utils/cmsUtils';

export function TopHeader(props) {
  const [menuOpened, setMenuOpened] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const { state } = useStore();
  const _themeColor =
    state.page && cmsUtils.payload(state.page, "ColorTheme", "pink");
  const themeColor =
    _themeColor &&
    _themeColor !== "" &&
    (_themeColor === "default" ? "pink" : _themeColor);

  console.log("themeColor", _themeColor);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      //console.log(currPos.x)
      //console.log(currPos.y);
      if (currPos.y > 90) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
      //setScrolled(currPos.y > 90)
    },
    [],
    null,
    true
  );

  function toggleMenu(e) {
    setMenuOpened(!menuOpened);
  }

  function onCloseMenu() {
    setMenuOpened(false);
  }
  function backTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  return (
    <Fragment>
      <header css={[style.top, scrolled && style.topScrolled]} id='top'>
        <BackButton />
        <SiteLink
          to='/'
          css={[style.logo, scrolled && style.logoScrolled]}
          onClick={backTop}
        >
          {themeColor && (
            <img
              css={style.logoImg}
              src={
                themeColor === "pink"
                  ? "/assets/logos/logo2.png"
                  : "/assets/logos/logo.png"
              }
              alt='logo'
            />
          )}
        </SiteLink>

        <Hamburger
          opened={menuOpened}
          toggleMenu={toggleMenu}
          scrolled={scrolled}
        />
        <NavBar open={menuOpened} onCloseMenu={onCloseMenu}></NavBar>
      </header>
    </Fragment>
  );
}
